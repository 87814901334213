import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import { QueryControl, QueryControlType } from '@/model/query-control';
import CommonService from '@/service/common';

export class WorkOrderOlaEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '服务部门',
        type: FormControlType.TREE_SELECT,
        optionsPromise: CommonService.getOrgs,
        required: true
    } as FormControlOptionModel)
    orgId: string = undefined;
    orgName: string = undefined;

    @FormControl({
        key: 'slaIds',
        label: '关联SLA',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAvailableOLASlas,
        optionsPromiseParam: 'id',
        mode: 'multiple',
        required: true
    } as FormControlOptionModel)
    slaIds: Array<string> = [];

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT_AREA
    } as FormControlTextModel)
    description: string = undefined;

    static getTableColumns() {
        return [
            {
                title: 'OLA名称',
                dataIndex: 'name'
            },
            {
                title: '服务部门',
                dataIndex: 'orgName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class WorkOrderOlaQueryModel extends QueryPageModel {
    @QueryControl({
        label: '名称',
        type: QueryControlType.TEXT
    })
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
