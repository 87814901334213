
import { WorkOrderOlaEntityModel, WorkOrderOlaQueryModel } from '@/entity-model/work-order-ola-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, put } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/ola`;

class WorkOrderOlaService implements ICRUDQ<WorkOrderOlaEntityModel, WorkOrderOlaQueryModel> {
    async create(model: WorkOrderOlaEntityModel):Promise<WorkOrderOlaEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderOlaEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new WorkOrderOlaEntityModel().toModel(res);
    }

    async update(model: WorkOrderOlaEntityModel):Promise<WorkOrderOlaEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: WorkOrderOlaEntityModel):Promise<WorkOrderOlaEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderOlaQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new WorkOrderOlaEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new ServiceAgreementEntityModel().toModel(item));
    }
}

export default new WorkOrderOlaService();
