



































































import { Component, Watch, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { WorkOrderSlaEntityModel, WorkOrderSlaQueryModel } from '@/entity-model/work-order-sla-entity';
import { WorkOrderOlaEntityModel, WorkOrderOlaQueryModel } from '@/entity-model/work-order-ola-entity';
import { WorkOrderUCEntityModel, WorkOrderUCQueryModel } from '@/entity-model/work-order-uc-entity';
import WorkOrderSlaService from '@/service/work-order-sla';
import WorkOrderOlaService from '@/service/work-order-ola';
import WorkOrderUCService from '@/service/work-order-uc';

@Component
export default class ServiceAgreementListComponent extends Mixins(TableDialogFormComponent) {
    treeData: Array<{title:string, key:string}> = [{ title: 'SLA管理', key: 'SLA' }, { title: 'OLA管理', key: 'OLA' }, { title: 'UC管理', key: 'UC' }];

    selectedKeys: Array<string> = ['SLA'];

    @Watch('selectedKeys')
    update() {
        switch (this.selectedKeys[0]) {
            case 'OLA':
                this.initTable({
                    service: WorkOrderOlaService,
                    queryModel: new WorkOrderOlaQueryModel(),
                    tableColumns: WorkOrderOlaEntityModel.getTableColumns()
                });
                break;
            case 'UC':
                this.initTable({
                    service: WorkOrderUCService,
                    queryModel: new WorkOrderUCQueryModel(),
                    tableColumns: WorkOrderUCEntityModel.getTableColumns()
                });
                break;
            default:
                this.initTable({
                    service: WorkOrderSlaService,
                    queryModel: new WorkOrderSlaQueryModel(),
                    tableColumns: WorkOrderSlaEntityModel.getTableColumns()
                });
                break;
        }
        this.getList();
    }

    created() {
        this.initTable({
            service: WorkOrderSlaService,
            queryModel: new WorkOrderSlaQueryModel(),
            tableColumns: WorkOrderSlaEntityModel.getTableColumns()
        });

        this.getList();
    }

    treeSelect(selectedKeys, e:{ selected: boolean, selectedNodes, node, event }) {
        this.selectedKeys = [e.node.eventKey];
    }

    newClick() {
        switch (this.selectedKeys[0]) {
            case 'OLA':
                this.addClick(new WorkOrderOlaEntityModel());
                break;
            case 'UC':
                this.addClick(new WorkOrderUCEntityModel());
                break;
            default:
                this.addClick(new WorkOrderSlaEntityModel());
                break;
        }
    }

    onSelectChange(item) {
        if (item.key === 'slaIds') {
            if (item.value && item.value.length > 0) {
                _.map(item.value, val => {
                    _.map(item.options, opt => {
                        if (opt.id === val) {
                            opt.disabled = true;
                        } else {
                            opt.disabled = false;
                        }
                    });
                });
            } else {
                _.map(item.options, opt => {
                    opt.disabled = false;
                });
            }
        }
    }
}
